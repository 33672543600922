@import '../../../styles/customMediaQueries.css';
.pillsWrapper {
  margin: 6px 0px;
  padding-bottom: 5px;
  display: flex;
  gap: 5px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    position: relative;
    right: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: gainsboro;
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--colorGrey300);
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  & .pill {
    display: inline-block;
    white-space: nowrap;
    padding: 2px 10px;
    font-size: 14px;
    border: 1px solid var(--marketplaceColor);
    color: var(--colorWhite);
    border-radius: 15px;
    background-color: var(--marketplaceColor);
    & .remove {
      margin-left: 10px;
      cursor: pointer;
      font-size: 18px;
    }
  }
}
