@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;

  @media (--viewportMedium) {
    position: relative;
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

/* Container for side and content */
.layoutWrapperContainer {
  composes: container;
  /* parent aka root is flexbox, this container takes all available space */
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
  }
}

.layoutWrapperFilterColumn {
  display: none;

  @media (--viewportMedium) {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px 0 0 24px;
  }
  @media (--viewportLarge) {
    /* Layout */
    padding: 40px 0 0 36px;

    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (min-width: 1440px) {
    padding: 40px 0 0 calc((100vw - 1368px) / 2);
  }
  @media (--viewportXLarge) {
    padding: 40px 0 0 calc((100vw - 1800px) / 2);
  }
}

.filterColumnContent {
}

.allFilters {
  & .filter {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: solid 1px var(--colorGrey100);

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}

.filterFooter {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--colorWhite);
  padding: 15px 24px;
  border: none;
  border-top: solid 1px var(--colorGrey100);
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.resetAllFiltersButton,
.resetAllButton {
  composes: marketplaceH5FontStyles from global;
  color: var(--colorGrey900);
  display: inline;
  padding: 0;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
  font-weight: var(--fontWeightSemiBold);
  outline: none;
  text-align: left;
  cursor: pointer;
  text-decoration: underline;
  border: none;
  transition: all ease 0.5s;

  @media (--viewportSmall) {
    font-size: 16px;
  }

  &:focus,
  &:hover {
    transition: all ease 0.5s;
    color: var(--marketplaceColor);
  }
}

.showResults {
  composes: marketplaceH5FontStyles from global;
  background-color: var(--colorGrey900);
  color: var(--colorWhite);
  display: inline;
  padding: 0;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
  font-weight: var(--fontWeightSemiBold);
  outline: none;
  text-align: left;
  cursor: pointer;
  border: none;
  padding: 10px 15px;
  transition: all ease 0.5s;

  @media (--viewportSmall) {
    padding: 10px 20px;
    font-size: 16px;
  }

  &:focus,
  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
    transition: all ease 0.5s;
  }
}

.resetAllFiltersButton {
  color: var(--marketplaceColor);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.layoutWrapperMain {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px 24px;
  max-width: calc(var(--contentMaxWidthPages) + 48px);
  width: 100%;
  margin: 0 auto;

  @media (--viewportMedium) {
    padding: 24px;
  }
  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 40px 24px;
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    padding: 0;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
  }
}

.error {
  color: var(--colorFail);
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.mainPanel {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 14px;
  }
}

.mainPanelMapVariant {
  composes: mainPanel;

  @media (--viewportMedium) {
    padding: 18px 24px 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 23px 36px 0 36px;
  }
}

.searchFiltersMobileMap {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersMobileList {
  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.listingsForGridVariant {
  composes: listings;
}
.listViewWrapper {
}

.listingsForMapVariant {
  composes: listings;
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}
.rootModal {
  position: relative;
  display: block;
  width: 100%;

  @media (--viewportMedium) {
    padding-top: 16px;
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.changeCardView {
  place-content: center flex-start;
  align-items: center;
  background-color: rgb(243, 243, 242);
  border-radius: 12px;
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  gap: 0px;
  height: 40px;
  overflow: visible;
  padding: 0px;
  position: relative;
  width: min-content;
  & .icon {
    place-content: center;
    align-items: center;
    border-radius: 8px;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row nowrap;
    gap: 10px;
    overflow: visible;
    padding: 0px;
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;
    @media (--viewportLarge) {
      width: 40px;
      height: 40px;
    }
    &.active {
      background-color: rgb(17, 17, 17);
      & > svg {
        &.gridIcon {
          & rect {
            stroke: rgba(255, 255, 255, 1);
          }
        }
        &.listIcon {
          & path {
            fill: rgba(255, 255, 255, 1);
            &:first-child {
              fill: rgba(255, 255, 255, 1);
            }
          }
        }
      }
    }
    & > svg {
      &.gridIcon {
        width: 16px;
        height: 16px;
        @media (--viewportLarge) {
          width: 18px;
          height: 18px;
        }
        & rect {
          stroke: rgba(17, 17, 17, 0.4);
        }
      }
      &.listIcon {
        width: 30px;
        height: 30px;
        @media (--viewportLarge) {
          width: 32px;
          height: 32px;
        }
        & path {
          fill: rgba(17, 17, 17, 0.4);
          &:first-child {
            fill: rgba(17, 17, 17, 0.4);
          }
        }
      }
    }
  }
}

.filters {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  @media (--viewportMedium) {
    padding: 0 40px;
  }

  @media (--viewportLarge) {
    width: calc(100% - 540px);
  }

  & .itemSlider {
    width: 100%;

    & .sliderItem {
    }
  }

  & .sliderControls {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--marketplaceColor) !important;
    color: var(--matterColorLight) !important;
    padding: 0;
    margin: 0;
    height: 3rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (--viewportMedium) {
      height: 3rem;
      width: 3rem;
    }

    @media (--viewportLarge) {
      height: 3rem;
      width: 6rem;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;

      & > svg {
        & > path {
          fill: var(--matterColorLight);
        }
      }
    }

    &.leftArrow {
      left: 10px;
      background-color: var(--matterColorAnti) !important;
      width: 24px;
      height: 24px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      border-radius: 100px !important;
      margin: 0 !important;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: var(--marketplaceColor) !important;
      }

      & > svg {
        width: 14px;
        height: 11px;
      }
    }

    &.rightArrow {
      right: 10px;
      background-color: var(--matterColorAnti) !important;
      width: 24px;
      height: 24px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      border-radius: 100px !important;
      margin: 0 !important;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: var(--marketplaceColor) !important;
      }

      & > svg {
        width: 14px;
        height: 11px;
      }
    }
  }

  & :global(.react-multi-carousel-list) {
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: initial;
  }

  & :global(.react-multi-carousel-track) {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    position: relative;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    will-change: transform, transition;
  }

  & .link {
    text-decoration: none;
    position: relative;
    transition: all ease 0.5s;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 0;

    &::after {
      content: '';
      bottom: 0px;
      left: 0;
      right: 0;
      width: 0;
      margin: 0 auto;
      position: absolute;
      background-color: var(--matterColorAnti);
      height: 2px;
      transition: all ease 0.5s;
    }

    &:hover {
      transition: all ease 0.5s;

      &::after {
        width: 80%;
        transition: all ease 0.5s;
        background-color: var(--matterColorDark);
      }

      & .quickFilter {
        & span {
          color: var(--matterColorDark);
        }

        & > svg {
          & path {
            fill: var(--matterColorDark);
          }

          & polygon {
            transition: all ease 0.5s;
            fill: var(--matterColorDark);
          }
        }
      }
    }

    &.active {
      transition: all ease 0.5s;

      &::after {
        width: 80%;
        background-color: var(--matterColorDark);
        transition: all ease 0.5s;
      }

      & .quickFilter {
        transition: all ease 0.5s;

        & span {
          transition: all ease 0.5s;
          color: var(--matterColorDark);
        }

        & > svg {
          transition: all ease 0.5s;

          & path {
            transition: all ease 0.5s;
            fill: var(--matterColorDark);
          }

          & polygon {
            transition: all ease 0.5s;
            fill: var(--matterColorDark);
          }
        }
      }
    }

    & .quickFilter {
      transition: all ease 0.5s;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;

      & img {
        width: 30px;
        margin-bottom: 5px;
      }

      & > svg {
        margin-bottom: 5px;
        transition: all ease 0.5s;

        & path {
          transition: all ease 0.5s;
          fill: var(--matterColorAnti);
        }

        & polygon {
          transition: all ease 0.5s;
          fill: var(--matterColorAnti);
        }
      }

      & span {
        color: var(--matterColorAnti);
        font-size: 13px;
        line-height: 116%;
        transition: all ease 0.5s;
        text-align: center;
      }
    }
  }
}
