@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: hsl(0deg 0% 98.04%);

  /* sticky result summary in mobile */
  position: sticky;
  top: 74px;
  z-index: 1;
  padding: 20px 0;
}

.searchResultSummary {
  composes: h3 from global;
  margin-bottom: 10px;
  margin-top: 0;
  color: var(--colorGrey900);

  display: flex;
  justify-content: space-between;
}

.buttons {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  & > button {
    height: 100%;
    min-height: 36px;
    margin: 0;
  }
}

.mapIcon {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* background map image */
  background-image: url(./images/map_icon216x105.png);
  background-size: cover;

  border-radius: 3px;
  width: 72px;
  height: 35px;
  padding: 9px 22px;
  margin: 0;
}

.modalContainer {
  width: 100%;
  height: 98%;
  min-height: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.filterFooter {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--colorWhite);
  padding: 15px 24px;
  border: none;
  border-top: solid 1px var(--colorGrey100);
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.resetAllFiltersButton,
.resetAllButton {
  composes: marketplaceH5FontStyles from global;
  color: var(--colorGrey900);
  display: inline;
  padding: 0;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
  font-weight: var(--fontWeightSemiBold);
  outline: none;
  text-align: left;
  cursor: pointer;
  text-decoration: underline;
  border: none;
  transition: all ease 0.5s;
  line-height: 100%;

  @media (--viewportSmall) {
    font-size: 16px;
  }

  &:focus,
  &:hover {
    transition: all ease 0.5s;
    color: var(--marketplaceColor);
  }
}

.showResults {
  composes: marketplaceH5FontStyles from global;
  background-color: var(--colorGrey900);
  color: var(--colorWhite);
  display: inline-block;
  width: auto;
  padding: 0;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
  font-weight: var(--fontWeightSemiBold);
  outline: none;
  text-align: center;
  cursor: pointer;
  border: none;
  padding: 10px 15px;
  transition: all ease 0.5s;
  min-height: auto;

  @media (--viewportSmall) {
    padding: 10px 20px;
    font-size: 16px;
  }

  &:focus,
  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
    transition: all ease 0.5s;
  }
}

.resetAllFiltersButton {
  color: var(--marketplaceColor);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}
.sortyByWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center; /* Align items to the top */
  gap: 10px;
  & > div {
    border: none;
    & > div {
      margin: 0;
    }
  }
}
.filtersWrapper {
  & > * {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: solid 1px var(--colorGrey100);
  }
}

.showListingsContainer {
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100vw;
  padding: 18px 24px;

  background: var(--colorWhite);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 11;
}

.showListingsButton {
  background-color: var(--marketplaceColor);
  display: inline-block;
  border-radius: 3px;
}
