@import '../../../styles/customMediaQueries.css';
.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.searchOptions {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 16px;
  gap: 10px;
  @media (--viewportLarge) {
    flex-direction: row;
    align-items: center;
  }
}

.searchResultSummary {
  margin: 0;
  display: flex;
  align-items: center;
}

.loadingResults {
  composes: textSmall from global;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 0 0 0;
  margin: 0;
}

.resultsFound {
  white-space: nowrap;
}
.sortyByWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center; /* Align items to the top */
  gap: 10px;
}
.keywordFilter {
  & > div {
    margin-top: 0;
  }
}

.sortyBy {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightRegular);
  padding: 8px 8px 8px 0;
}
.filterButton {
  width: auto;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  background-color: var(--colorWhite);
  color: var(--colorGrey900);
  border: solid 1px var(--colorGrey100);
  min-height: auto;
  height: 40px;
  &:hover,
  &:focus,
  &:active {
    background-color: var(--colorWhite);
    border: solid 1px var(--colorGrey300);
    box-shadow: none;
    color: var(--colorGrey900);
  }
  & > svg {
    width: 18px;
    height: 18px;
    margin-right: 6px;
    & path {
      stroke: var(--colorGrey900);
    }
  }
}
.leftFilters {
  display: flex;
  flex-direction: column;
  @media (--viewportLarge) {
    width: 50%;
  }
}
.filtersApplied {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
  width: 50%;
  & .filterName {
    background-color: var(--colorGrey50);
    border: solid 1px var(--colorGrey100);
    background-color: var(--colorWhite);
    padding: 6px 10px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--borderRadiusLarge);
    font-size: 13px;
    color: var(--colorGrey700);
    font-weight: var(--fontWeightRegular);
    line-height: 100%;
    transition: var(--transitionStyle);
    &:hover {
      transition: var(--transitionStyle);
      border-color: var(--marketplaceColor);
      background-color: var(--colorWhite);
      & .clear {
        transition: var(--transitionStyle);
        & > svg {
          transition: var(--transitionStyle);
          & path {
            transition: var(--transitionStyle);
            fill: var(--marketplaceColor);
          }
        }
      }
    }
    & .clear {
      transition: var(--transitionStyle);
      cursor: pointer;
      margin-left: 5px;
      & > svg {
        transition: var(--transitionStyle);
        width: 14px;
        height: 14px;
        & path {
          fill: var(--colorGrey600);
          transition: var(--transitionStyle);
        }
      }
    }
  }
  & .clearAll {
    text-decoration: underline;
    color: var(--marketplaceColor);
    font-size: 14px;
    line-height: 100%;
    margin-left: 5px;
    cursor: pointer;
    transition: var(--transitionStyle);
    &:hover {
      transition: var(--transitionStyle);
      color: var(--colorGrey900);
    }
  }
}
