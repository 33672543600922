@import '../../../styles/customMediaQueries.css';
.quickFilters {
  display: flex;
  gap: 14px;
  padding: 0px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  overflow-x: auto;
  margin-bottom: 5px;
  @media (--viewportMedium) {
    margin-bottom: 0;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    position: relative;
    right: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: gainsboro;
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--colorGrey300);
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.quickFilter {
  display: flex;
  white-space: nowrap;
  flex-direction: column;
  font-size: 12px;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 9px;
  }

  color: var(--colorGrey300);
  &.selected {
    color: var(--colorGrey900);
  }

  & > img {
    max-width: 100%;
    height: 30px;
  }
}
